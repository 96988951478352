import React from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  Avatar,
  Divider,
  useMediaQuery,
  Link as MuiLink,
} from '@mui/material'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ModeCommentOutlined from '@mui/icons-material/ModeCommentOutlined'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import InstagramCarousel from './InstagramCarousel'
import { Link } from 'react-router-dom'
import footer_logo from '../photo/cds.png'

export const ConnectAlumni = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <>
      <Box py={4}>
        <Divider
          textAlign="left"
          sx={{ color: 'rgba(1,47,109,1)' }}
          component="div"
          role="presentation"
        >
          <Typography variant="h4" textAlign={'left'}>
            CONNECT
            {isMobile ? <br /> : ' '}
            WITH ALUMNI
          </Typography>
        </Divider>
      </Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Card sx={{ maxWidth: '420px', padding: '24px', boxShadow: 3 }}>
            <Box display={'flex'}>
              <Avatar src={footer_logo} />
              <Box
                display={'flex'}
                flexGrow={1}
                flexDirection={'column'}
                marginX={1}
              >
                <Typography variant="body1">Cheongna Dalton School</Typography>
                <MuiLink
                  variant="body2"
                  href="https://www.instagram.com/cds_phoenixrising/"
                  target="_blank"
                  rel="noopener"
                >
                  @cds_phoenixrising
                </MuiLink>
              </Box>
              <MoreVertIcon />
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                height: '264px',
                marginTop: '4px',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '93%',
                  overflow: 'hidden',
                }}
              >
                <InstagramCarousel />
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                  width: '100%',
                  height: '100%',
                  paddingTop: '11px',
                }}
              >
                <FavoriteIcon sx={{ marginRight: '4px', color: 'red' }} />
                <ModeCommentOutlined sx={{ marginRight: '4px' }} />
                <SendOutlinedIcon sx={{ marginRight: '4px' }} />
                <Box display={'flex'} flexGrow={1}></Box>
                <BookmarkBorderIcon />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Card
            sx={{
              width: '80%',
              minWidth: '305px',
              height: '264px',
              backgroundColor: 'rgba(1,47,109,1)',
              color: '#fff',
              padding: '24px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="h4">Alumni Directory</Typography>
              <Box mt={1} />
              <Divider sx={{ borderColor: 'white' }} />
              <Box mt={1} />
              <Typography variant="subtitle1">
                A directory with the information of all our Alumni. <br /> Sign
                in with a verified account to view.
              </Typography>
            </Box>

            <Link style={{ display: 'block', margin: 'auto' }} to="/directory">
              <Button variant="contained" color="secondary">
                Check Directory
              </Button>
            </Link>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
