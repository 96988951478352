import React, { useEffect, useState } from 'react'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  FormControl,
  Select,
  MenuItem,
  TextField,
  ButtonBase,
  Alert,
  Snackbar,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import headerimage from '../photo/graduation.JPG'
import { Parallax } from 'react-parallax'
import { getDirectoryList } from '../API/api'
import WindowList from '../components/Directory/WindowList'
import { capitalize } from 'lodash'
import { useAppContext } from '../AppContext'

function DirectoryPage() {
  const navigate = useNavigate()
  const [dataList, setDataList] = useState([])
  const [sort, setSort] = useState(0)
  const [filters, setFilters] = useState({
    name: '',
    college: '',
    country: '',
    major: '',
    current_occupation: '',
  })
  const { setAlertOpen } = useAppContext()
  const getData = async () => {
    try {
      const Data = await getDirectoryList()
      setDataList(Data)
    } catch (error) {
      console.error(error)
      setAlertOpen(true)
      navigate('/signin')
    }
  }

  const sortArray = (data) => {
    if (sort === 0) {
      return data.slice().sort((a, b) => a.name.localeCompare(b.name))
    } else {
      return data
        .slice()
        .sort(
          (a, b) =>
            parseInt(b.year_of_graduation) - parseInt(a.year_of_graduation),
        )
    }
  }

  const filterData = (data) => {
    const filteredData = data.filter((item) => {
      for (const field in filters) {
        const query = filters[field].toLowerCase()
        const value = String(item[field]).toLowerCase()
        if (!value.includes(query)) {
          return false
        }
      }
      return true
    })
    return filteredData
  }

  const filteredSortData = () => {
    return filterData(sortArray(dataList))
  }

  const handleSort = (value) => {
    setSort(value)
  }

  const handleInputChange = (field) => (event) => {
    setFilters({
      ...filters,
      [field]: event.target.value,
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <Parallax
        bgImage={headerimage}
        strength={300}
        position={'absolute'}
        renderLayer={(percentage) => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(1,47,109,0.5)',
            }}
          />
        )}
      >
        <Box
          height={['200px', '200px']}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography
            color="secondary"
            variant="h2"
            component="h2"
            textAlign={'center'}
          >
            <strong>DIRECTORY</strong>
          </Typography>
        </Box>
      </Parallax>
      <Container maxWidth="lg">
        <Box pt={4} />
        <Typography variant="h3">Alumni Directory</Typography>
        <Box pt={2} />
        <Grid container spacing={8}>
          <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="body1">Sort: </Typography>
              <Box pl={1} />
              <FormControl variant="standard">
                <Select
                  value={sort}
                  autoWidth
                  onChange={(e) => {
                    handleSort(e.target.value)
                  }}
                >
                  <MenuItem value={0}>Alphabetically</MenuItem>
                  <MenuItem value={1}>Year</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {dataList && dataList.length && (
              <WindowList dataList={filteredSortData()} />
            )}
          </Grid>
          <Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
            <Card sx={{ padding: '12px' }}>
              <Box display={'flex'} alignItems={'center'}>
                <TuneIcon />
                <Box pl={2} />
                <Typography variant="h5">Filter By</Typography>
              </Box>
              <Grid container spacing={1}>
                {[
                  'name',
                  'college',
                  'country',
                  'major',
                  'current_occupation',
                ].map((index, key) => (
                  <Grid item xs={6} md={12} key={key}>
                    <TextField
                      label={capitalize(index.replace(/_/g, ' '))}
                      value={filters[index]}
                      variant="standard"
                      onChange={handleInputChange(index)}
                      fullWidth
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  xs={6}
                  md={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <ButtonBase
                    sx={{ fontSize: '18px' }}
                    onClick={() => {
                      setFilters({
                        name: '',
                        college: '',
                        country: '',
                        major: '',
                        current_occupation: '',
                      })
                    }}
                  >
                    reset
                  </ButtonBase>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Box pt={10} />
    </>
  )
}
export default DirectoryPage
