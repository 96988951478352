import { createBrowserRouter, Outlet } from 'react-router-dom'
import App from './App'
import ErrorPage from './pages/ErrorPage'
import EventsPage from './pages/EventsPage'
import Footer from './components/Footer'
import Header from './components/Header'
import DirectoryPage from './pages/DirectoryPage'
import StoriesPage from './pages/StoriesPage'
import SignInPage from './pages/SignInPage'
import SignUpPage from './pages/SignUpPage'
import { Box, styled } from '@mui/material'

const AppWrapper = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
const OutletWrapper = styled(Box)`
  flex: 1;
`
const HeaderWrapper = styled(Box)`
  flex-grow: 0;
`
function Layout() {
  return (
    <AppWrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>

      <OutletWrapper>
        <Outlet />
      </OutletWrapper>

      <Footer />
    </AppWrapper>
  )
}

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <App />,
        label: 'main',
      },
      {
        path: '/stories',
        element: <StoriesPage />,
        label: 'Alumni Stories',
      },
      {
        path: '/events',
        element: <EventsPage />,
        label: 'Alumni Event Calander',
      },
      {
        path: '/directory',
        element: <DirectoryPage />,
        label: 'Alumni Directory',
      },
      {
        path: '/signin',
        element: <SignInPage />,
        label: 'Sign in',
      },
      {
        path: '/signup',
        element: <SignUpPage />,
        label: 'Sign Up',
      },
    ],
    errorElement: <ErrorPage />,
  },
])
