import React from 'react'
import cat from '../photo/cat.jpg'
import Carousel from 'react-material-ui-carousel'
import { Container, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  height: 240px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  img {
    object-fit: cover;
    transition: all 0.3s ease;
  }
  img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`

function MainCarousel({ clickHandler, storyData }) {
  const ListCarousel = [
    { title: 'Class of 2024', name: 'test', desc: 'test' },
    { title: 'Class of 2025', name: 'test', desc: 'test' },
    { title: 'Class of 2026', name: 'test', desc: 'test' },
    { title: 'Class of 2027', name: 'test', desc: 'test' },
  ]

  return (
    <>
      <Container maxWidth="sm" sx={{ display: { md: 'none' }, color: '#fff' }}>
        <Carousel
          swipe={true}
          animation="slide"
          indicators={false}
          duration={550}
        >
          {storyData.slice(0, 6).map((item, i) => (
            <a onClick={() => clickHandler(item)}>
              <ImageWrapper>
                <img key={i} src={item.thumbnail} />
              </ImageWrapper>
              <Box mb={1} />
              <Typography variant="h3">{item.title}</Typography>
            </a>
          ))}
        </Carousel>
        <Box mt={'42px'} />
      </Container>
    </>
  )
}
export default MainCarousel
