import React, { useState } from 'react'
import logo from '../photo/cds_logo.svg'
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  Drawer,
  Divider,
  Chip,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { styled as muiStyled } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import { useAppContext } from '../AppContext'
import { handleLogout } from '../API/api'

const CustomHeader = muiStyled(AppBar)`
  background-color:'white';
`
function Header() {
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const { user, isVerified, setLogoutOpen } = useAppContext()
  const navigate = useNavigate()

  const MenuItemList = ({ styles }) => {
    return (
      <>
        {user ? (
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="h6">
              Hello <strong>{user.displayName}</strong>
            </Typography>
            <Box pl={1} />

            <Chip
              color={isVerified ? 'success' : 'default'}
              label={isVerified ? 'Verified' : 'Unverified'}
              size="small"
              sx={{ borderRadius: '4px' }}
            />
            <Box pl={2} />
          </Box>
        ) : (
          <></>
        )}
        <>
          <Link to="/events">
            <Button color="primary" onClick={() => setOpen(false)}>
              Events
            </Button>
          </Link>
          <Divider />
          <Link to="/stories">
            <Button color="primary" onClick={() => setOpen(false)}>
              Stories
            </Button>
          </Link>
          <Divider />
          <Button
            color="primary"
            onClick={() => navigate('/directory')}
            disabled={!isVerified}
          >
            Directory
          </Button>
          <Button
            color="primary"
            href="http://daltonschool.kr/one_board/board.php?board=internship"
            target="_blank"
            rel="noopener noreferrer"
            variant="outlined"
            sx={{ ...styles }}
            onClick={() => setOpen(false)}
          >
            Internship
          </Button>
          {user ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ ...styles }}
              onClick={() => {
                handleLogout(setLogoutOpen)
              }}
            >
              Sign Out
            </Button>
          ) : (
            <Link to="/signin">
              <Button
                variant="contained"
                color="primary"
                sx={{ ...styles }}
                onClick={() => setOpen(false)}
              >
                Sign In
              </Button>
            </Link>
          )}
        </>
      </>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomHeader color="secondary" position="static">
        <Toolbar>
          <Grid container>
            <Grid item xs={8} md={4} sx={{ margin: 'auto 0' }}>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: '100%', maxWidth: '320px' }}
                />
              </Link>
            </Grid>
            <Grid
              item
              xs={4}
              md={8}
              sx={{ display: 'flex', justifyContent: 'end' }}
            >
              {isMobile ? (
                <React.Fragment key={'right'}>
                  <Button
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    <MenuIcon fontSize="large" />
                  </Button>
                  <Drawer
                    anchor={'bottom'}
                    open={open}
                    onClose={() => {
                      setOpen(false)
                    }}
                    sx={{ justifyContent: 'end' }}
                  >
                    <Stack spacing={1} margin={4}>
                      <MenuItemList styles={{ width: '100%' }} />
                    </Stack>
                  </Drawer>
                </React.Fragment>
              ) : (
                <MenuItemList styles={{ marginLeft: '8px' }} />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </CustomHeader>
    </Box>
  )
}
export default Header
