import React, { useEffect, useState } from 'react'
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Divider,
  Modal,
  Button,
  Skeleton,
} from '@mui/material'
import { styled } from '@mui/system'
import headerimage from '../photo/graduation.JPG'
import { Parallax } from 'react-parallax'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { SubTitle } from '../customStyles'
import MainCarousel from './MainCarousel'
import AlumniStoriesGrid from './AlumniStoriesGrid'
import { useAppContext } from '../AppContext'

const ModalWrapper = styled(Container)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  padding: 32px;
  height: 100%;
`
const ModalBox = styled(Box)`
  width: 100%;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  height: 85%;
  overflow: auto;
  border-radius: 4px;
`
export function AlumniStoriesModal({ open, handleClose, selectedData }) {
  let value = ''
  if (selectedData && selectedData.content) {
    value = selectedData.content
    value = value.replace(/<br\s*\/?>/gi, '\n')
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalWrapper disableGutters maxWidth="md">
        <ModalBox>
          <Box sx={{ backgroundColor: 'gray', width: '100%' }}>
            {selectedData && selectedData.video && (
              <video controls width={'100%'}>
                <source src={selectedData.video} />
              </video>
            )}
          </Box>
          <Box>
            <ReactMarkdown>{value}</ReactMarkdown>
          </Box>
        </ModalBox>
        <Box mt={2} />
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{ width: '100%' }}
        >
          Close
        </Button>
      </ModalWrapper>
    </Modal>
  )
}
function ParallaxSection() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)
  const handleOpen = (data) => {
    setOpen(true)
    setSelectedData(data)
  }
  const handleClose = () => setOpen(false)
  const [selectedData, setSelectedData] = useState([])

  const { storyDataList } = useAppContext()

  return (
    <Parallax
      bgImage={headerimage}
      strength={300}
      position={'absolute'}
      bgImageStyle={{ top: '-50%' }}
      renderLayer={(percentage) => (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',

            background:
              'linear-gradient(0deg, rgba(1,47,109,1) 30%, rgba(1,47,109,0) 100%)',
          }}
        />
      )}
    >
      <Box
        height={['200px', '200px', '30vh']}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        py={20}
      >
        <SubTitle
          fontcolor="white"
          width="50%"
          leftline={true}
          rightline={true}
          style={{ minWidth: '200px' }}
        >
          <Typography variant="h3" textAlign={'center'}>
            Proud CDS Alumni
          </Typography>
        </SubTitle>
        <Typography color="secondary" variant="h1" textAlign={'center'}>
          <strong>
            ONCE A PHEONIX, <br /> ALWAYS A PHOENIX
          </strong>
        </Typography>
      </Box>
      <Container maxWidth={'xl'}>
        <Box sx={{ display: 'flex' }}>
          <SubTitle
            rightline={true}
            flexgrow={1}
            pr={'16px'}
            style={{ whiteSpace: 'nowrap' }}
          >
            <Typography variant="h4" color="white">
              ALUMNI{isMobile ? <br /> : ' '}STORIES
            </Typography>
          </SubTitle>
          <Link to="/stories" style={{ display: 'block', margin: 'auto' }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{ height: '32px', margin: 'auto' }}
            >
              More
            </Button>
          </Link>
        </Box>
        <Box pt={5} />
        {isMobile ? (
          storyDataList ? (
            <MainCarousel
              clickHandler={(data) => handleOpen(data)}
              storyData={storyDataList}
            />
          ) : (
            <Skeleton height={'360px'} sx={{ bgcolor: 'grey.100' }} />
          )
        ) : storyDataList ? (
          <AlumniStoriesGrid
            clickHandler={(data) => handleOpen(data)}
            storyData={storyDataList}
          />
        ) : (
          <Skeleton height={'360px'} sx={{ bgcolor: 'grey.100' }} />
        )}
      </Container>
      <AlumniStoriesModal
        open={open}
        handleClose={handleClose}
        selectedData={selectedData}
      />
    </Parallax>
  )
}
export default ParallaxSection
