import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import useMediaQuery from '@mui/material/useMediaQuery'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import RoomIcon from '@mui/icons-material/Room'
import {
  Box,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Stack,
  Drawer,
  Card,
  Avatar,
  Divider,
} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { useAppContext } from '../AppContext'

const UpcomingEvents = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const now = new Date()
  const { eventDataList } = useAppContext()
  const lorem =
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  function getMonthAbbreviation(monthIndex) {
    const monthAbbreviations = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    return monthAbbreviations[monthIndex]
  }
  const EventCard = ({ data: { date, title, time, place, content } }) => {
    return (
      <Card
        display={'flex'}
        flexDirection={'column'}
        sx={{
          ':hover': {
            boxShadow: 20, // theme.shadows[20]
            cursor: 'pointer',
          },
          padding: '16px',
          boxSizing: 'border-box',
          maxWidth: '300px',
          height: '340px',
          margin: '0 auto',
        }}
      >
        <Stack spacing={1} height="100%" overflow="hidden">
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'}>
              <Typography variant="h4">{date}</Typography>
            </Box>
          </Box>
          <Typography variant="h5">
            <strong>{title}</strong>
          </Typography>
          <Box display={'flex'}>
            <CalendarMonthIcon />
            <Box ml={1} />
            <Typography variant="body2">{time}</Typography>
          </Box>
          <Box display={'flex'}>
            <RoomIcon />
            <Box ml={1} />
            <Typography variant="body2">{place}</Typography>
          </Box>
          <Box display={'flex'} overflow="auto">
            <Typography variant="body2">{content}</Typography>
          </Box>
        </Stack>
      </Card>
    )
  }
  return (
    <Container maxWidth={'xl'}>
      <Box py={4}>
        <Divider
          textAlign="left"
          sx={{ color: 'rgba(1,47,109,1)' }}
          component="div"
          role="presentation"
        >
          <Typography variant="h4">
            UPCOMING{isMobile ? <br /> : ' '}EVENTS
          </Typography>
        </Divider>
        {/* <SubTitle color={'rgba(1,47,109,1)'} line={'right'}>
    <Typography variant="h4"> UPCOMING EVENTS</Typography>
  </SubTitle> */}
      </Box>
      {eventDataList &&
        (isMobile ? (
          <Container maxWidth="sm">
            <Carousel
              swipe={true}
              animation="slide"
              indicators={true}
              duration={550}
            >
              {eventDataList.map((data, i) => (
                <Box padding={'16px'} margin={'auto auto'}>
                  <EventCard data={data} />
                </Box>
              ))}
            </Carousel>
          </Container>
        ) : (
          <Container maxWidth={'lg'}>
            <Grid container spacing={[4, 4, 8, 16]}>
              {eventDataList.slice(0, 3).map((data, index) => (
                <Grid item xs={12} sm={12} md={4} key={index}>
                  <EventCard data={data} />
                </Grid>
              ))}
            </Grid>
          </Container>
        ))}
    </Container>
  )
}
export default UpcomingEvents
