import React from 'react'
import cat from '../photo/cat.jpg'
import Carousel from 'react-material-ui-carousel'
import logo from '../photo/footer logo white.svg'
import footer_logo from '../photo/cds.png'
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
} from '@mui/material'
import RoomIcon from '@mui/icons-material/Room'
import MailIcon from '@mui/icons-material/Mail'
import CallIcon from '@mui/icons-material/Call'
import { styled as muiStyled } from '@mui/system'

const FooterWrapper = muiStyled(Box)`
  background-color:#333;
  width:100%;
  padding:16px;
  box-sizing: border-box;
`
function Footer() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  return (
    <FooterWrapper>
      <Grid container mt={6}>
        <Grid mx={4} mb={2}>
          <img
            style={{ width: '100%', maxWidth: '150px' }}
            src={footer_logo}
          />
        </Grid>
        {isMobile ? (
          <Grid marginLeft={4}>
            <Box color="white">
              <Box>
                <img src={logo} />
              </Box>
              <Typography>Cheongna Dalton School</Typography>
              <Box display={'flex'}>
                <RoomIcon />
                <Box ml={1} />
                <Typography>
                  344, Cheomdandong-ro, Seo-gu, Incheon (22742)
                </Typography>
              </Box>
              <Box display={'flex'}>
                <MailIcon />
                <Box ml={1} />
                <Typography>info@daltonschool.kr</Typography>
              </Box>
              <Box display={'flex'}>
                <CallIcon />
                <Box ml={1} />
                <Typography>Phone: 032-563-0523 / Fax: 032-563-0524</Typography>
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid>
            <Box color="white">
              <Box>
                <img src={logo} />
              </Box>
              <Typography>Cheongna Dalton School</Typography>
              <Box display={'flex'}>
                <RoomIcon />
                <Box ml={1} />
                <Typography>
                  344, Cheomdandong-ro, Seo-gu, Incheon (22742)
                </Typography>
              </Box>
              <Box display={'flex'}>
                <MailIcon />
                <Box ml={1} />
                <Typography>info@daltonschool.kr</Typography>
              </Box>
              <Box display={'flex'}>
                <CallIcon />
                <Box ml={1} />
                <Typography>Phone: 032-563-0523 / Fax: 032-563-0524</Typography>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <Box display={'flex'} justifyContent={'center'} pt={8} pb={5}>
        <Typography color="secondary">© Cheongna Dalton School</Typography>
      </Box>
    </FooterWrapper>
  )
}
export default Footer
