import React from 'react'
// @ts-ignore
import { VariableSizeList } from 'react-window'
import { Item } from './Item'

class WindowList extends React.Component {
  listRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      posts: this.props.dataList,
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.dataList !== this.props.dataList) {
      this.setState({
        posts: this.props.dataList,
      })
    }
  }
  render() {
    const { posts } = this.state
    return (
      <VariableSizeList
        ref={this.listRef}
        itemData={posts}
        itemCount={posts.length}
        itemSize={this.getItemSize.bind(this)}
        height={600}
      >
        {this.postRow.bind(this)}
      </VariableSizeList>
    )
  }
  postRow({ data, index, style }) {
    return (
      <div style={style}>
        {data[index] && (
          <Item
            data={data[index]}
            heightChanged={(height) => this.heightChangedHandle(height, index)}
          />
        )}
      </div>
    )
  }

  heightChangedHandle(height, index) {
    this.state.posts[index].height = height
    // @ts-ignore
    this.listRef.current.resetAfterIndex(index)
  }

  getItemSize(index) {
    return this.state.posts[index].height || 200
  }
}

export default WindowList
