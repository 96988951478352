import React, { useState } from 'react'
import cat from '../photo/cat.jpg'
import { Container, Box, Grid, Typography, Stack } from '@mui/material'
import headerimage from '../photo/graduation.JPG'
import { Parallax } from 'react-parallax'
import { GridWrapper } from '../components/AlumniStoriesGrid'
import { AlumniStoriesModal } from '../components/ParallaxSection'
import { useAppContext } from '../AppContext'

function StoriesPage() {
  const [open, setOpen] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const handleOpen = (data) => {
    setOpen(true)
    setSelectedData(data)
  }
  const handleClose = () => setOpen(false)
  const { storyDataList } = useAppContext()
  return (
    <>
      <Parallax
        bgImage={headerimage}
        strength={400}
        position={'absolute'}
        renderLayer={(percentage) => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(1,47,109,0.5)',
            }}
          />
        )}
      >
        <Box
          height={['200px', '200px']}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <Typography
            color="secondary"
            variant="h2"
            component="h2"
            textAlign={'center'}
          >
            <strong>Stories</strong>
          </Typography>
        </Box>
      </Parallax>
      <Container maxWidth="md">
        <Grid container spacing={0}>
          {storyDataList &&
            storyDataList.map((v, i) => (
              <Grid item xs={12} sm={6} md={4} sx={{ padding: 0 }}>
                <a onClick={() => handleOpen(v)}>
                  <Box margin={'16px'}>
                    <GridWrapper>
                      <img
                        key={i}
                        src={v.thumbnail}
                        style={{ height: '100%' }}
                      />
                    </GridWrapper>
                    <Typography
                      color={'primary'}
                      variant="subtitle1"
                      textAlign={'left'}
                    >
                      {v.title}
                    </Typography>
                  </Box>
                </a>
              </Grid>
            ))}
        </Grid>
        <AlumniStoriesModal
          open={open}
          handleClose={handleClose}
          selectedData={selectedData}
        />
      </Container>
    </>
  )
}
export default StoriesPage
