import React, { useEffect, useState } from 'react'
import { Box, Container } from '@mui/material'
import { styled as muiStyled } from '@mui/system'
import AlumniNewsletter from './components/AlumniNewsletter'
import ParallaxSection from './components/ParallaxSection'
import UpcomingEvents from './components/UpcomingEvents'
import { ConnectAlumni } from './components/ConnectAlumni'
import { useLocation } from 'react-router-dom'

const FullContainer = muiStyled(Container)`
  overflow-y: hidden;
  margin:0;
`

//sx={{fontFamily:'Pretendard-Regular'}}
function App(props) {
  return (
    <div className="App">
      <FullContainer maxWidth={false} disableGutters>
        <ParallaxSection />
        <Container maxWidth={'xl'}>
          <UpcomingEvents />
          <Box pt={10} />
          <AlumniNewsletter />
          <Box pt={10} />
          <ConnectAlumni />
          <Box pt={10} />
        </Container>
      </FullContainer>
    </div>
  )
}

export default App

//asojdfnosafnosafnono
//laptop
