import React from 'react'
import cat from '../photo/cat.jpg'
import I1 from '../photo/insta1.jpg'
import I2 from '../photo/insta2.jpg'
import I3 from '../photo/insta3.jpg'
import I4 from '../photo/insta4.jpg'
import I5 from '../photo/insta5.jpg'
import I6 from '../photo/insta6.jpg'
import Carousel from 'react-material-ui-carousel'
import { Container, Box } from '@mui/material'
import { styled } from '@mui/system'

const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
`

function InstagramCarousel() {
  const ListCarousel = [
    { title: 'test', name: 'test', desc: 'test', src: I1 },
    { title: 'test', name: 'test', desc: 'test', src: I2 },
    { title: 'test', name: 'test', desc: 'test', src: I3 },
    { title: 'test', name: 'test', desc: 'test', src: I4 },
    { title: 'test', name: 'test', desc: 'test', src: I5 },
    { title: 'test', name: 'test', desc: 'test', src: I6 },
  ]

  return (
    <Carousel swipe={true} animation="slide" indicators={true} duration={550}>
      {ListCarousel.map((item, i) => (
        <ImageWrapper>
          <img key={i} src={item.src} width={'100%'}/>
        </ImageWrapper>
      ))}
    </Carousel>
  )
}
export default InstagramCarousel
