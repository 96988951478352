import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { getNews } from '../API/api'

function AlumniNewsletter({ pdfUrl }) {
  const [itemFrameCode, setItemFrameCode] = useState([])

  const getData = async () => {
    try {
      const Data = await getNews()
      setItemFrameCode(Data['news-iframe-code'])
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  // useMemo를 사용하여 메모이제이션
  const memoizedIframe = useMemo(
    () => <div dangerouslySetInnerHTML={{ __html: itemFrameCode }} />,
    [itemFrameCode],
  )

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Typography
            variant="h4"
            textAlign={'center'}
            color={'rgba(1,47,109,1)'}
          >
            ARCHES: THE CDS
            <br /> ALUMNI NEWSLETTER
          </Typography>
          <Container maxWidth={'sm'}>
            <Box marginY={2}>
              <Divider />
            </Box>
            <Typography color={'rgba(1,47,109,1)'} textAlign={'center'}>
              A monthly newsletter featuring Alumni events and Alumni
              interviews. Subscribe to receive it in your mail.
            </Typography>
            <Box mb={3} />
          </Container>
        </Box>
      </Grid>
      <Grid
        item
        sm={12}
        md={6}
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        <Box
          sx={{
            maxWidth: '560px',
            width: '100%',
            height: '620px',
          }}
        >
          {memoizedIframe}
        </Box>
      </Grid>
    </Grid>
  )
}

export default AlumniNewsletter
