import { Alert, Snackbar, Typography } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getEvent, getStoryList } from './API/api'

const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const auth = getAuth()
  const [user, setUser] = useState(null)
  const [isVerified, setIsVerified] = useState(false)
  const [storyDataList, setStoryDataList] = useState(null)
  const [eventDataList, setEventDataList] = useState(null)

  useEffect(() => {}, [alertOpen])
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertOpen(false)
    setLogoutOpen(false)
  }
  const getStoryData = async () => {
    try {
      const Data = await getStoryList()
      setStoryDataList(Data)
    } catch (error) {
      console.error(error)
    }
  }

  const getEventData = async () => {
    try {
      const Data = await getEvent()
      setEventDataList(Data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getStoryData()
    getEventData()
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user)
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            // idTokenResult.claims 에 custom claims가 있음
            const customClaims = idTokenResult.claims

            // customClaims 객체에서 role 가져오기
            setIsVerified(customClaims.verified)
          })
          .catch((error) => {
            console.error('Error getting custom claims:', error)
            setIsVerified(false)
          })
      } else {
        setUser(null)
        setIsVerified(false)
      }
    })
    return () => unsubscribe()
  }, [auth])
  return (
    <AppContext.Provider
      value={{
        setLogoutOpen,
        setAlertOpen,
        user,
        isVerified,
        storyDataList,
        eventDataList,
      }}
    >
      {children}
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity="error">
          <Typography variant="h6">
            {user ? 'You are not verifed yet' : 'You should Login first!'}
          </Typography>
        </Alert>
      </Snackbar>
      <Snackbar
        open={logoutOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity="info">
          <Typography variant="h6">Sign out successfully!</Typography>
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
