import React from 'react'
import cat from '../photo/cat.jpg'
import Carousel from 'react-material-ui-carousel'
import {
  Container,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Alert,
  AlertTitle,
} from '@mui/material'
import RoomIcon from '@mui/icons-material/Room'
import MailIcon from '@mui/icons-material/Mail'
import CallIcon from '@mui/icons-material/Call'
import headerimage from '../photo/graduation.JPG'
import { styled as muiStyled } from '@mui/system'
import { Parallax } from 'react-parallax'
import GoogleIcon from '@mui/icons-material/Google'
import { handleLogout, handleSignIn } from '../API/api'
import { useAppContext } from '../AppContext'

function SignInPage() {
  const { user, setLogoutOpen } = useAppContext()
  const loginPopup = async () => {
    const result = await handleSignIn()
  }

  return (
    <>
      <Parallax
        bgImage={headerimage}
        strength={900}
        position={'absolute'}
        renderLayer={(percentage) => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(1,47,109,0.5)',
            }}
          />
        )}
      >
        <Box
          height={['200px', '200px', '200px']}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Typography
            color="secondary"
            variant="h2"
            component="h2"
            textAlign={'center'}
          >
            <strong>Sign In / Sign Up</strong>
          </Typography>
        </Box>
      </Parallax>
      <Container maxWidth="sm">
        <Box
          alignItems={'center'}
          display={'flex'}
          flexDirection={'column'}
          pb={8}
        >
          <Box pt={8} />
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            After signing up,
            <strong>
              additional inquiries regarding identity verification
            </strong>
            and authorization will be handled by the administrators. <br />
            Please direct any further questions to 'Here'
          </Alert>
          <Box pt={4} />
          {user ? (
            <Button
              variant="outlined"
              fullWidth
              sx={{ height: '48px' }}
              onClick={() => {
                try {
                  handleLogout(setLogoutOpen)
                } catch (error) {}
              }}
            >
              Sign Out
            </Button>
          ) : (
            <Button
              variant="outlined"
              fullWidth
              sx={{ height: '48px' }}
              startIcon={<GoogleIcon />}
              onClick={loginPopup}
            >
              Sign In or Sign Up with Google
            </Button>
          )}
        </Box>
      </Container>
    </>
  )
}
export default SignInPage
