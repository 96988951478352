import { Parallax } from 'react-parallax'
import headerimage from '../photo/graduation.JPG'
import React, { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import ReactMarkdown from 'react-markdown'
import {
  Box,
  Button,
  Card,
  Container,
  Modal,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { useAppContext } from '../AppContext'

const ModalWrapper = styled(Container)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  padding: 32px;
  height: 100%;
`
const ModalBox = styled(Box)`
  width: 100%;
  background-color: #fff;
  padding: 16px;
  box-sizing: border-box;
  height: 80%;
  overflow: scroll;
  border-radius: 4px;
  margin: auto;
`

const initialValue = dayjs()

const handleEventModalOpen = () => {}

function ServerDay(props) {
  const {
    highlightedDays = [],
    day,
    day: { $M },
    outsideCurrentMonth,
    ...other
  } = props

  const isSelected =
    !outsideCurrentMonth &&
    highlightedDays &&
    highlightedDays.indexOf(day.date()) >= 0
  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? '🌚' : undefined}
      style={{ cursor: 'pointer', zIndex: '999' }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  )
}

export default function EventsPage() {
  const requestAbortController = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [highlightedDays, setHighlightedDays] = useState([1, 2, 15])
  const { eventDataList } = useAppContext()
  const [selectedDate, setSelectedDate] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedEventData, setSelectedEventData] = useState()
  const [selectedEventDataList, setSelectedEventDataList] = useState()
  let selectedMonth = dayjs().month()

  function fakeFetch(date, eventData, { signal }) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        let daysToHighlight = []
        let monthEventDataList = []
        eventData &&
          eventData.map(({ date: eventDate }, index) => {
            const dateObject = dayjs(eventDate)
            if (selectedMonth === dateObject.month()) {
              const dayOfMonth = dateObject.date()
              daysToHighlight.push(dayOfMonth)
              monthEventDataList.push(eventData[index])
            }
          })
        resolve({ daysToHighlight, monthEventDataList })
      }, 500)

      signal.onabort = () => {
        clearTimeout(timeout)
        reject(new DOMException('aborted', 'AbortError'))
      }
    })
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController()
    fakeFetch(date, eventDataList, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight, monthEventDataList }) => {
        setHighlightedDays(daysToHighlight)
        setSelectedEventDataList(monthEventDataList)
        setIsLoading(false)
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error
        }
      })

    requestAbortController.current = controller
  }
  useEffect(() => {
    fetchHighlightedDays(eventDataList || initialValue)
    // abort request on unmount
    return () => requestAbortController.current?.abort()
  }, [eventDataList])

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort()
    }
    let { $d } = date || 0
    selectedMonth = $d.getMonth()
    setIsLoading(true)
    setHighlightedDays([])
    fetchHighlightedDays(date)
  }
  const handleDayClick = (date) => {
    setSelectedDate(date)
    const indexOf = highlightedDays.indexOf(date)
    if (indexOf !== -1) {
      setSelectedEventData(selectedEventDataList[indexOf])
      setIsModalOpen(true)
    }
  }

  // 모달을 닫기 위한 함수
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  return (
    <>
      <Parallax
        bgImage={headerimage}
        strength={300}
        position={'absolute'}
        renderLayer={(percentage) => (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(1,47,109,0.5)',
            }}
          />
        )}
      >
        <Box
          height={['200px', '200px']}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <Typography
            color="secondary"
            variant="h2"
            component="h2"
            textAlign={'center'}
          >
            <strong>Events</strong>
          </Typography>
        </Box>
      </Parallax>
      <Container maxWidth={'md'}>
        <Box pt={4} />
        <Card>
        {isMobile ? (
          <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              defaultValue={initialValue}
              loading={!eventDataList}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: (props) => (
                  <ServerDay
                    {...props}
                    highlightedDays={highlightedDays}
                    onClick={() => handleDayClick(props.day.date())}
                  />
                ),
              }}
              slotProps={{
                day: {
                  highlightedDays,
                },
              }}
            />
          </LocalizationProvider>
        </Box>
        ) : (
          <Box px={4} pt={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                defaultValue={initialValue}
                loading={!eventDataList}
                onMonthChange={handleMonthChange}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{
                  day: (props) => (
                    <ServerDay
                      {...props}
                      highlightedDays={highlightedDays}
                      onClick={() => handleDayClick(props.day.date())}
                    />
                  ),
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        )}
          
        </Card>
        <Box pt={4} />
      </Container>
      {selectedEventData && (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <ModalWrapper disableGutters maxWidth="md">
            <ModalBox>
              <Typography variant="h6">{selectedEventData.title}</Typography>
              <Box mt={2} />
              <Typography variant="subtitle2">
                {selectedEventData.date}
              </Typography>
              <Typography variant="subtitle2">
                {selectedEventData.time}
              </Typography>
              <Typography variant="subtitle2">
                {selectedEventData.place}
              </Typography>
              <Box mt={2} />
              <ReactMarkdown>{selectedEventData.content}</ReactMarkdown>
            </ModalBox>
            <Box mt={2} />
            <Button
              variant="contained"
              onClick={handleCloseModal}
              sx={{ width: '100%' }}
            >
              Close
            </Button>
          </ModalWrapper>
        </Modal>
      )}
    </>
  )
}
