import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, Card } from '@mui/material'

const directoryIndex = [
  'year_of_graduation',
  'college',
  'major',
  'country',
  'current_occupation',
  'email',
]

export const Item = (props) => {
  const [opened, setOpened] = useState(false)
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    // @ts-ignore
    const heightL = ref.current.offsetHeight
    setHeight(heightL)
    props.heightChanged(heightL)
  }, [opened])

  const toggle = () => {
    setOpened(!opened)
    props.heightChanged(height)
  }

  return (
    <a onClick={toggle} ref={ref} style={{ cursor: 'pointer' }}>
      <Box
        sx={{
          padding: '4px',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            padding: '12px',
            width: '100%',
            flexDirection: 'column',
            boxSizing: 'border-box',
          }}
        >
          <Box display={'flex'}>
            <Typography variant="h6">{props.data.name}</Typography>
            <Box display={'flex'} flexGrow={1} />
            <Typography variant="h6">
              {props.data.year_of_graduation}
            </Typography>
          </Box>
          {opened && (
            <>
              <Box pt={2} />
              <Grid container>
                {directoryIndex.map((_, index) => (
                  <>
                    <Grid item xs={4}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {_.replace(/_/g, ' ')}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {props.data && (
                        <Typography textAlign={'right'} variant="subtitle1">
                          {props.data[_]}
                        </Typography>
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          )}
        </Card>
      </Box>
    </a>
  )
}
