import React from 'react'
import { styled } from '@mui/system'
import { Box } from '@mui/material'

export const SubTitle = styled(Box)(
  ({ FullWidth, width, fontcolor, rightline, leftline, color, flexgrow }) => `
    font-size: 30px;
    align-items: center;
    width: ${width || '100%'};
    color: ${fontcolor || 'white'};
    display: flex;
    ${
      FullWidth
        ? 'width: 100%;'
        : flexgrow
          ? 'flex-grow: 1;'
          : width
            ? `width: ${width};`
            : ''
    }
    ${
      rightline &&
      `
            &::after {
              flex: 1;
              content: '';
              padding: 1px;
              background-color: ${color || 'white'};
              margin: 5px;
            }
          `
    }
    ${
      leftline &&
      `
          &::before {
            flex: 1;
            content: '';
            padding: 1px;
            background-color: ${color || 'white'};
            margin: 5px;
          }
        `
    }
  `,
)
