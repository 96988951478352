import { createTheme } from '@mui/material/styles'
import { createBreakpoints } from '@mui/system'

const breakpoints = createBreakpoints({})
const theme = createTheme({
  palette: {
    primary: {
      main: '#012f6d',
    },
    secondary: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: `"Pretendard-Regular", "Helvetica", "Arial", sans-serif`,
    h1: {
      [breakpoints.down('lg')]: {
        fontSize: '4rem',
      },
      [breakpoints.down('md')]: {
        fontSize: '3.5rem',
      },
      [breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
    },
    h3: {
      [breakpoints.down('lg')]: {
        fontSize: 24,
      },
      [breakpoints.down('md')]: {
        color: 'white',
        fontSize: 18,
      },
    },
    h4: {
      [breakpoints.down('lg')]: {
        fontSize: '2.125rem',
      },
      [breakpoints.down('md')]: {
        fontSize: '1.7rem',
      },
    },
    title: {
      fontSize: 36,
    },
  },
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '100% !important',
          height: '420px',
          maxHeight: '800px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          height: 'auto !important',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekContainer: {
          [breakpoints.up('lg')]: {
            margin: '20px 0px',
          },
          [breakpoints.down('md')]: {
            margin: '10px 0px',
          },
          justifyContent: 'space-between !important',
        },

        header: {
          marginTop: '16px',
          justifyContent: 'space-between !important',
        },

        slideTransition: {
          overflowX: 'visible',
          height: '100%',
        },
      },
    },
  },
})
export default theme
