import React from 'react'
import cat from '../photo/cat.jpg'
import Carousel from 'react-material-ui-carousel'
import { Container, Box, Grid, Typography, Stack } from '@mui/material'
import RoomIcon from '@mui/icons-material/Room'
import MailIcon from '@mui/icons-material/Mail'
import CallIcon from '@mui/icons-material/Call'
import headerimage from '../photo/graduation.JPG'
import { styled as muiStyled } from '@mui/system'
import { Parallax } from 'react-parallax'
import { useAppContext } from '../AppContext'

function SignUpPage() {
  return (
    <Parallax
      bgImage={headerimage}
      strength={900}
      position={'absolute'}
      renderLayer={(percentage) => (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background:
              'linear-gradient(0deg, rgba(1,47,109,1) 30%, rgba(1,47,109,0) 100%)',
          }}
        />
      )}
    >
      <Box
        height={['200px', '200px', '420px']}
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        pt={20}
      >
        <Typography
          color="secondary"
          variant="h1"
          component="h1"
          textAlign={'center'}
        >
          <strong>Directory</strong>
        </Typography>
      </Box>
    </Parallax>
  )
}
export default SignUpPage
