import React, { useState } from 'react'
import cat from '../photo/cat.jpg'
import { Box, Container, Grid, Modal, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const GridWrapper = styled(Box)`
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 240px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 4px;
  img {
    object-fit: cover;
    transition: all 0.3s ease;
  }
  img:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`
function AlumniStoriesGrid({ clickHandler, storyData }) {
  return (
    <>
      <Grid container spacing={0}>
        {storyData.slice(0, 6).map((value, key) => (
          <Grid item md={4} sx={{ padding: 0 }} key={key}>
            <a onClick={() => clickHandler(value)}>
              <Box margin={'16px'}>
                <GridWrapper>
                  <img src={value.thumbnail} style={{ height: '100%' }} />
                </GridWrapper>
                <Typography
                  color={'secondary'}
                  variant="subtitle1"
                  textAlign={'left'}
                >
                  {value.title}
                </Typography>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default AlumniStoriesGrid
