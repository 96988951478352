import { collection, getDocs, getDoc } from 'firebase/firestore'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  getFirestore,
} from 'firebase/storage'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { db, storage } from '../token/firebaseConfig'
import { useAppContext } from '../AppContext'

export const getDirectoryList = async () => {
  try {
    const collectionData = collection(db, 'alumni_directory')
    const querySnapshot = await getDocs(collectionData)
    if (querySnapshot === null) {
      return []
    } else {
      return querySnapshot.docs.map((doc) => doc.data())
    }
  } catch (error) {
    console.error('Error in getDirectoryList:', error)
    throw error
  }
}

export const getStoryList = async () => {
  try {
    const collectionData = collection(db, 'alumni_stories')
    const querySnapshot = await getDocs(collectionData)
    if (querySnapshot === null) {
      return []
    } else {
      return querySnapshot.docs.map((doc) => doc.data())
    }
  } catch (error) {
    console.error('Error in getStoryList:', error)
    throw error
  }
}

export const getNews = async () => {
  try {
    const collectionData = collection(db, 'alumni_news')
    const querySnapshot = await getDocs(collectionData)
    if (querySnapshot === null) {
      return []
    } else {
      return querySnapshot.docs[0]?.data()
    }
  } catch (error) {
    console.error('Error in getNews:', error)
    throw error
  }
}

export const getEvent = async () => {
  try {
    const collectionData = collection(db, 'alumni_event')
    const querySnapshot = await getDocs(collectionData)
    if (querySnapshot === null) {
      return []
    } else {
      return querySnapshot.docs.map((doc) => doc.data())
    }
  } catch (error) {
    console.error('Error in getEvent:', error)
    throw error
  }
}

export const handleSignIn = async () => {
  const provider = new GoogleAuthProvider()
  const auth = getAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const { user } = result

    if (user) {
      return true
    } else {
      return false
    }
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code
    const errorMessage = error.message
    // The email of the user's account used.
    const email = error.customData.email
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error)

    console.error('Login Error:', error)
    return false
  }
}
export const handleLogout = async (setLogoutOpen) => {
  const auth = getAuth()
  try {
    await signOut(auth)
    setLogoutOpen(true)
    return { success: true } // 성공 시
  } catch (error) {
    console.error('Error during logout: ', error)
    return { success: false, error } // 실패 시
  }
}
